import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

    public form: FormGroup;

    type1 = 'text';
    type2 = 'text';

    constructor(
        public api: ApiService,
        private formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({
            password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(15)])],
            confirmPassword: ['', Validators.required],
        });
    }

    ngOnInit() {
    }

    changeType(num) {
        if (num === 1) {
            if (this.type1 === 'text') {
                this.type1 = 'password';
            } else {
                this.type1 = 'text';
            }
        } else {
            if (this.type2 === 'text') {
                this.type2 = 'password';
            } else {
                this.type2 = 'text';
            }
        }
    }

    forgotPasswordFun() {
        const urlParams = window.location;
        const res = urlParams.toString().split('=');
        if (this.form.valid) {
            if (this.form.value.password !== this.form.value.confirmPassword) {
                this.api.toastr.error('Confirm password should same with new password');
                return;
            }
        } else {
            Object.keys(this.form.controls).forEach(key => {
                this.form.controls[key].markAsTouched({onlySelf: true});
            });
        }


// const formData = new FormData();
// formData.append('_id', res[1]);

// this.api.postData(this.api.apiUrl.forgotPassword, formData)
//     .subscribe(res => {
//         },
//         () => {
//
//         });
    }
}
