import {ApplicationRef, Component, NgZone, OnInit} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {filter} from 'rxjs/operators';
import {Router} from '@angular/router';
import {PlatformLocation} from '@angular/common';
import {Location} from '@angular/common';
import {ApiService} from './services/api.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    message;
    subscription: Subscription;
    loading: false;

    constructor(
        private api: ApiService, private swUpdate: SwUpdate,
        private zone: NgZone, location: PlatformLocation, public router: Router,
        private _location: Location, private applicationRef: ApplicationRef
    ) {
        location.onPopState(() => {
            this.zone.run(() => {
                setTimeout(() => {
                    applicationRef.tick();
                }, 500);
            });
        });
        this.subscription = this.api.loaderStatus.subscribe(status => {
            this.loading = status;
        });
    }

    ngOnInit() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm('New version available. Load New Version?')) {
                    window.location.reload();
                }
            });
        }
    }

}
