import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-user-payment-details',
  templateUrl: './user-payment-details.component.html',
  styleUrls: ['./user-payment-details.component.css']
})
export class UserPaymentDetailsComponent implements OnInit {
  @Input() lesson;

  constructor() { }

  ngOnInit() {
    console.log(this.lesson);
  }

}
