import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    public form: FormGroup;
    public loader = false;
    rememberMeControl = new FormControl(false);

    constructor(
        private formBuilder: FormBuilder,
        public api: ApiService
    ) {
        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.pattern(this.api.constant.EMAIL_REGEX)])],
            password: ['', Validators.required]
        });
    }

    ngOnInit() {
        if (localStorage.getItem('rememberMe')) {
            this.rememberMeControl.patchValue(true);
            this.form.patchValue(JSON.parse(localStorage.getItem('rememberData')));
        }
    }

    loginFun() {
        if (this.form.valid) {
            this.loader = true;
            this.api.postData(this.api.apiUrl.adminLogin, this.form.value, false)
                .subscribe(res => {
                        if (this.rememberMeControl.value) {
                            localStorage.setItem('rememberMe', this.rememberMeControl.value);
                            localStorage.setItem('rememberData', JSON.stringify(this.form.value));
                        } else {
                            localStorage.removeItem('rememberMe');
                            localStorage.removeItem('rememberData');
                        }
                        localStorage.setItem('comvoToken', res.data.accessToken);
                        if(localStorage.getItem('lastLoginTime') != null || localStorage.getItem('lastLoginTime') != undefined){
                            localStorage.setItem('lastLoginTime', localStorage.getItem('lastLoginTime'));
                        } else if(localStorage.getItem('lastLoginTime') == null || localStorage.getItem('lastLoginTime') == undefined){
                            localStorage.setItem('lastLoginTime',JSON.stringify(new Date()));
                            
                        }
                       
                        this.api.navigate('dashboard', '');
                    },
                    () => {
                        this.loader = false;
                    });
        } else {
            Object.keys(this.form.controls).forEach(key => {
                this.form.controls[key].markAsTouched({onlySelf: true});
            });
        }
    }

}
