import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as constant from './constants';
import { apiUrl } from './apiUrls';
import { ToastrService } from 'ngx-toastr';
import { Lightbox } from 'ngx-lightbox';
import { BehaviorSubject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiService {

    constant = constant;
    apiUrl = apiUrl;

    noImage = '/assets/images/noImage.png';
    userPlaceholder = '/assets/images/ic_user.png';
    placeholder = '/assets/images/placeholder.jpg';
    gif = '/assets/images/gif.gif';

    modalRef: BsModalRef;

    public previousUrl: string;
    public currentUrl: string;
    public heading: string;

    constructor(
        private router: Router,
        private http: HttpClient,
        public toastr: ToastrService,
        public lightBox: Lightbox,
        public modalService: BsModalService,
    ) {
        this.apiEndpoint = environment.apiBaseUrl;
        this.currentUrl = this.router.url;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    public readonly apiEndpoint: String;
    private subject = new BehaviorSubject<any>(null);
    public title = this.subject.asObservable();

    private loaderSubject = new BehaviorSubject<any>(null);
    public loaderStatus = this.loaderSubject.asObservable();

    goBackFun() {
        this.navigate(this.previousUrl, '');
    }

    navigate(url, params) {
        if (params) {
            this.router.navigate([`/${url}`, params]);
        } else {
            this.router.navigate([`/${url}`]);
        }
    }

    changeTitle(title) {
        this.subject.next(title);
        this.heading = title;
    }

    loaderOn(loaderStatus) {
        this.loaderSubject.next(loaderStatus);
    }

    getData(url, obj, loader) {
        let params = new HttpParams();
        Object.keys(obj).forEach(key => {
            if (obj[key]) {
                params = params.set(key, obj[key]);
            }
        });
        return this.http.get<any>(this.apiEndpoint + url, { params: params, reportProgress: loader });
    }

    postData(url, data, loader) {
        return this.http.post<any>(this.apiEndpoint + url, data, { reportProgress: loader });
    }

    putData(url, data, loader) {
        return this.http.put<any>(this.apiEndpoint + url, data, { reportProgress: loader });
    }

    deleteData(url, loader) {
        return this.http.delete<any>(this.apiEndpoint + url, { reportProgress: loader });
    }

    openLightBox(data) {
        if (data) {
            const temp: any = [];
            temp.push({
                src: data,
                thumb: data
            });
            this.lightBox.open(temp, 0);
            return true;
        }
    }

    uploadImage(url, file) {
        if (file && file.type.match('image.*')) {
            const formData = new FormData();
            formData.append('image', file);
            return this.http.post<any>(this.apiEndpoint + url, formData, { reportProgress: true });
        }
    }

    checkImage(file) {
        if (file) {
            if (file.size < 5000000) {
                if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                    return true;
                } else {
                    this.toastr.error('Please add jpg or png image only');
                }
            } else {
                this.toastr.error('Please add image less than 5 MB');
            }
        }
        return false;
    }

    showModal(template, size?: any) {
        if (size) {
            this.modalRef = this.modalService.show(template,
                {
                    backdrop: 'static',
                    keyboard: false,
                    class: `gray modal-${size}`
                }
            );
        } else {
            this.modalRef = this.modalService.show(template,
                {
                    backdrop: 'static',
                    keyboard: false,
                }
            );
        }
    }

    hideModal() {
        this.modalRef.hide();
    }

    blocked() {
        this.toastr.success('Blocked Successfully');
    }

    unblocked() {
        this.toastr.success('Unblocked Successfully');
    }

    updated() {
        this.toastr.success('Updated Successfully');
    }
    limit9(status) {
        this.toastr.success(status);
    }
    added() {
        this.toastr.success('Added Successfully');
    }

    deleted() {
        this.toastr.success('Deleted Successfully');
    }

    appendParams(myParams: { [x: string]: any; }): HttpParams {
        let params = new HttpParams();
        if (!!myParams) {
            for (const key of Object.keys(myParams)) {
                // if (data[key] || key == 'skip')
                params = params.append(key, myParams[key]);
            }
        }
        return params;
    }

    /**
  * @method (get http request)
  * @param url (api url)
  
  * @param backGroundUrl (don't want to show loader for any request pass true)
  */
    downloadCsv(url: string): Observable<any> {
        // const searchParams = this.appendParams(data);
        const apiUrl = `${environment.apiBaseUrl}${url}`;
        return this.http.get(apiUrl, { responseType: 'blob' })
            .pipe(map(response => {
                return response;
            }));
    }

}

