export const apiUrl = {

    adminLogin: 'admin/login',
    getDashboardData: 'admin/getDashboardData',
    users: 'admin/userListing',
    posts: 'admin/postListing',
    debates: 'admin/debateListing',
    blockUnblockUser: 'admin/blockUnblockUser',
    activeInnactiveCategory: 'admin/activeInnactiveCategory',
    addCategory: 'admin/addCategory',
    editCategory: 'admin/editDebateCategory',
    listCategory: 'admin/listCategory',
    deleteCategory: 'admin/deleteCategory',
    verifyUser: 'admin/verifyUser',
    sendNotification: 'admin/sendNotification',
    getDebateListing: 'admin/getDebateListing',
    uploadFile: 'common/uploadFile',
    addEditDebate: 'admin/addEditDebate',
    editFeatureDebate:'admin/editFeaturedDebate',
    csvFileDownload: 'admin/userListingCsv',
    adminConfig: 'admin/adminConfig',
    updateCommision: 'admin/adminConfig',
    paymentDetails: 'admin/paymentDetails',
    getFeatureDebateListing: 'admin/getFeatureDebateListing',
    putCategorySerialNo: 'admin/editCategorySerials',
    deleteUser: 'admin/deleteUserAccount',
    paymentDetailsOfUser: 'admin/paymentDetailsOfUser',
    getDiscoverUser: 'common/getDiscoverUser',
    addDiscoverUser:'admin/addDiscoverUser'

};

