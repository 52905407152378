export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const ARABIC = '[\u0600-\u06FF]';
export const ENGLISH = '^[a-zA-Z ]+$';

export const sideBarAdmin = [
    { path: '/dashboard', icon: 'fa fa-th-large', children: [], title: 'Dashboard' },
    { path: '/users', icon: 'fas fa-users', children: [], title: 'Users' },
    { path: '/discoverUsers', icon: 'fas fa-users', children: [], title: 'Discover Users' },
    // {path: '/posts', icon: 'fas fa-mail-bulk', children: [], title: 'Posts'},
    { path: '/featuredDebates', icon: 'fas fa-eye', children: [], title: 'Featured Debates' },

    { path: '/categories', icon: 'fas fa-flag', children: [], title: 'Categories' },
    { path: '/debates', icon: 'fas fa-eye', children: [], title: 'Debates' },
    // {path: '/reports', icon: 'fas fa-file-alt', children: [], title: 'Reports'},
    { path: '/send-notification', icon: 'far fa-bell', children: [], title: 'Notifications' },
    { path: '/stripPayments', icon: 'fas fa-money-bill-wave', children: [], title: 'Payments history' },
    { path: '/payments-commision', icon: 'fas fa-money-bill-wave', children: [], title: 'Payment Commision' },


    // {
    //     path: 'owners', icon: 'fa fa-flag', title: 'Owner Data', children: [
    //         {path: '/fields', icon: '', children: [], title: 'Field List'},
    //         {path: '/owners', icon: '', children: [], title: 'Owner List'},
    //         {path: '/contracts', icon: '', children: [], title: 'Contracts'},
    //     ]
    // },
];
